@import 'bootstrap/dist/css/bootstrap.min.css';

body{
  background-color: #282c34;
  color:#f3ba2f;
}
.App {
  text-align: center;
  max-width: 1140px;
  margin:0 auto;
}

.App-logo {
  height: 150px;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  min-height: 170px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
a{
  color:#ffffff;
}
.week{ 
  padding-right:40px;
}
.coin{
  color:#ffffff;
  display:flex;
  width:100%;
  flex-direction: row;
  justify-content: flex-start;
  -webkit-justify-content: flex-start;
  -moz-justify-content: flex-start;
  align-items: center;
  background-color: #f3ba2f;
  margin-bottom: 15px;
  padding:10px 15px;
  border-radius: 3px;
  text-decoration: none;
  transition: .2s background-color linear;
  text-align: left;
}
.coin:hover,
.coin:focus,
.coin:active{
  background-color: #dfa000;
  outline: none;
  color:#fff;
}
.coin img{
  height: 30px;
  border-radius: 50%;
}
.coin h2,
.coin h1{
  padding-left: 20px ;
  line-height: 1;
}
.coin h1{
  font-size: 18px;
  width: 20%;
}
.coin h2{
  font-size: 16px;
  width: 10%;
}
.text-center{
  text-align: center;
}
.coinIsBi img{
  width: 15px;
  height: 15px;
  display: inline-block;
}
.wales-table{
  width: 320px;
  margin:0 auto;
  vertical-align: top;
  min-height: 120px;
}
.wales-table td{
  vertical-align: top;
}
.br-light-grey{
  border-right: 1px solid #dddddd;
  padding-right: 3px;
}
.btn{
  width: auto!important;
  display: inline-block!important;
  padding-left: 15px;
  padding-right: 15px;
  padding-top:10px!important;
  padding-bottom:10px!important;
  margin-top: 25px;
  color:#ffffff;
}
.mnh-70{
  min-height: 70px;
}
.mnh-80{
  min-height: 80px;
}
.mnh-100{
  min-height: 100px;
}
.mnh-120{
  min-height: 120px;
}
.mnh-220{
  min-height: 220px;
}
.mnh-250{
  min-height: 250px;
}
.mnh-300{
  min-height: 300px;
}
.mnh-350{
  min-height: 350px;
}
.mnh-400{
  min-height: 400px;
}
.r-2{
  margin-right: 20px;
}
@media (max-width: 922px) {
  .table-responsive{
    overflow-x: scroll;
    overflow-y: inherit;
  }
  .week{
    margin:0
  }

  .coin h1{
    width: 20%;
    font-size: 12px;
    padding-left: 5px;
  }
  .coin h2{
    font-size: 12px;
    width: 20%;
    padding-left: 5px;
  }
  .App p{
    padding:15px;
  }
}
ul.text-start li{
  margin-bottom: 12px;
}
.bbu{
  font-size:12px
}